body a {
  text-decoration: none;
}
img {
  border-radius: 5px;
}

$min-top: -2;
$max-top: 100;
$min-left: -2;
$max-left: 100;
$max-width: 40;
$max-blue: 255;
$colors: #8982cb, #6a7db2, #51569d, #6599c0, #8fceff, #a592f7, #49e7ed, #6599c0,
  #74aee9, #7d99dd;

@for $l from 1 through 10 {
  @keyframes #{ border-transform +  $l} {
    @for $i from 0 through 10 {
      #{($i * 10%)} {
        top: #{random($max-top - $min-top)}vw;
        left: #{random($max-left - $min-left)}vw;
      }
    }
  }
  #{ ".video-bg" + $l} {
    animation: #{border-transform + $l} 70s infinite linear both;
    width: #{$l * 3}vw;
    height: #{$l * 3}vw;
    background-color: nth($colors, $l);
  }
}

.video-bg0 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  filter: blur(50px);
  opacity: 70%;
}
